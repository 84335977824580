
jQuery(function() {

    var animationBlock = $('.animation-with-content-wrapper');

    var countController = new ScrollMagic.Controller();

    $('.animation-with-content-wrapper').each(function(i) {

        let _this = $(this);
        

        new ScrollMagic.Scene({
            triggerElement:$(this)[0],
            triggerHook: "onCenter",
            duration:0
            })
            // .addIndicators()
            .addTo(countController)
            .on('start end', function(e) {

                $('.animation-stat', _this).each(function(i) {
                    var number = $(this)[0];
                    var numberStop = $(this).attr('data-stop');
                    var numberFormat = $(this).attr('data-format');

                    od = new Odometer({
                        el:number,
                        value:0,
                        format:numberFormat,
                        duration:10000
                    })
                    od.render();
                    od.update(numberStop);



                })


            })
    })

});




