// $( document ).ready( function() {

jQuery(function() {

    if($('.hs-element').length > 0) {

        window.addEventListener("resize", function () {
            ScrollTrigger.refresh();
        });

        let mm = gsap.matchMedia();
        
        mm.add("(min-width: 991px)", () => {
            
            const h_panels = gsap.utils.toArray(".h-panel");
            const backgroundImage = $('.hs-background-pattern');
    
            let maxWidth = 0;
            const getMaxWidth = () => {
            maxWidth = 0;
            h_panels.forEach((h_panel) => {
                maxWidth += h_panel.offsetWidth;
            });
            };
            getMaxWidth();
            ScrollTrigger.addEventListener("refreshInit", getMaxWidth);
    
    
            gsap.to(backgroundImage, 1, {x:'-20%', ease:Linear.easeNone}),
    
            gsap.to(h_panels, {
            x: () => `-${maxWidth - window.innerWidth}`,
            ease: "none",
            scrollTrigger: {
                trigger: ".hs-element-slide-wrapper",
                pin: true,
                scrub: true,
                end: () => `+=${maxWidth}`,
                invalidateOnRefresh: true,
                scrub:true,
            }
            });
    
            h_panels.forEach((sct, i) => {
    
                let start = "top top-=" + (sct.offsetLeft - window.innerWidth / 2) * (maxWidth / (maxWidth - window.innerWidth));
                let end = "+=" + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth));
    
                if(i == 0) {
                    start = "top center-=50";
                }
                if(i % 2 == 0) {
                    gsap.set(sct.firstElementChild, { opacity: 0, y: -100 });
                } else {
                    gsap.set(sct.firstElementChild, { opacity: 0, y:100 });
                }
                ScrollTrigger.create({
                    // markers:true,
                    trigger: sct.firstElementChild,
                    start: () => start,
                    end: () => end,
                    toggleClass: { targets: sct, className: "active" },
                    invalidateOnRefresh: true,
                    scrub:true,
                    onEnter: () => {
    
                        gsap.to(sct.firstElementChild, {opacity: 1, y:0, duration: 0.4, ease: "power1.inOut"});
                        
                    },
                    onLeaveBack: () => {
                        if(i % 2 == 0) {
                            gsap.to(sct.firstElementChild, {opacity: 0, y: -100, duration: 0.4, ease: "power1.inOut"});
                        } else {
                            gsap.to(sct.firstElementChild, {opacity: 0, y: 100, duration: 0.4, ease: "power1.inOut"});
                        }
                    },
                });
            });

        })

        mm.add("(max-width: 991px)", () => {

            const h_panels = gsap.utils.toArray(".mobile-panel");
            h_panels.forEach((sct, i) => {
                if(i % 2 == 0) {
                    gsap.set(sct, { opacity: 0, x: -200 });
                } else {
                    gsap.set(sct, {opacity:0, x:200});
                }
                ScrollTrigger.create({
                    // markers:true,
                    trigger: sct,
                    start: 'top center+=100',
                    end:'bottom center+=100',
                    invalidateOnRefresh: true,
                    scrub:true,
                    onEnter: () => {
                        gsap.to(sct, {opacity: 1, x:0, duration: 0.4, ease: "power1.inOut"});
                    },
                    onLeaveBack: () => {
                        if(i % 2 == 0) {
                            gsap.to(sct, {opacity: 0, x:-200, duration: 0.4, ease: "power1.inOut"});
                        } else {
                            gsap.to(sct, {opacity: 0, x:200, duration: 0.4, ease: "power1.inOut"});
                        }
                    }
                })
            });
        });

    }

  });
