jQuery(window).on('resize', function($) {

    // console.log('The window has been resized.');
    var subMenuBox = jQuery('.sub-menu-box');
    // console.log(jQuery('.mj-navigation-wrapper').outerHeight());
    subMenuBox.css({'top':jQuery('.mj-navigation-wrapper').outerHeight()});
    var subMenuBoxWidth = subMenuBox.outerWidth();
    var subMenuBoxHeight = subMenuBox.outerHeight();
    var subMenuColumns = 1;
    var subMenuList = jQuery('.mj-menu > .menu > li:has(ul) > .sub-menu-wrapper');

    subMenuList.css({
    'height':subMenuBoxHeight + 25,
    'width':subMenuBoxWidth,
    });
    let anchorLinkNav = jQuery('.anchor-link-navigation');
    anchorLinkNav.css({
        'top':jQuery('.mj-navigation-wrapper').outerHeight()
    });

    if(jQuery(window).outerWidth() > 991) {
        if(jQuery('.mobile-hamburger-wrapper').hasClass('open')) {
            jQuery('.mobile-hamburger-wrapper').removeClass('open');
        }
        if(jQuery('.mj-mobile-menu-takeover').hasClass('open')) {
            jQuery('.mj-mobile-menu-takeover').removeClass('open');
        }
    }

    if(jQuery(window).outerWidth() < 992) {
        
    }





});