jQuery(function() {

    if ($('.offset-overlay-scroll-magic').length > 0) {

        var offsetOverlayController = new ScrollMagic.Controller();

        var section = $('.offset-overlay-scroll-magic');

        section.each(function() {

            var _this = $(this);

            var slides = $('div.overlay-panel', _this);

            var firstSlide = $('div.overlay-panel-1', _this);

            var overlay = $('.overlay.pink', _this);

            var timeline = new TimelineMax();

            if (slides.length > 1) {

                slides.each(function(i) {

                    if (i == 0) {
                        if (_this.offset().top == 0) {
                            TweenMax.to(firstSlide, 0, { autoAlpha: 1, ease: Power2.easeInOut });
                            TweenMax.from(firstSlide.find('.overlay-panel-content'), 0.75, { y: 200, opacity: 0, ease: Power2.easeInOut });
                            TweenMax.from(firstSlide.find('.corner.top-left'), 0.5, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut });
                            TweenMax.from(firstSlide.find('.corner.top-right'), 0.5, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut });
                            TweenMax.from(firstSlide.find('.corner.bottom-right'), 0.5, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut });
                            TweenMax.from(firstSlide.find('.corner.bottom-left'), 0.5, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut });

                            timeline.to($(this).find('.overlay-panel-content'), 2, { y: -200, opacity: 0, ease: Power2.easeInOut }, "+=1")
                                .call(() => {
                                    if (!$(this).hasClass('active')) {
                                        $(this).addClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).addClass('active');
                                        $('.offset-indicator-wrapper', _this[0]).addClass('on');
                                    } else {
                                        $(this).removeClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).removeClass('active');
                                        $('.offset-indicator-wrapper', _this[0]).removeClass('on');
                                    }
                                });;
                            timeline.to($(this).find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        } else {
                            TweenMax.to(firstSlide, 0, { autoAlpha: 1, ease: Power2.easeInOut });
                            timeline.from(firstSlide.find('.overlay-panel-content'), 2, { y: 200, opacity: 0, ease: Power2.easeInOut }, '-=1')
                                .call(() => {
                                    if (!$(this).hasClass('active')) {
                                        $(this).addClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).addClass('active');
                                        $('.offset-indicator-wrapper', _this[0]).addClass('on');
                                    } else {
                                        $(this).removeClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).removeClass('active');
                                        $('.offset-indicator-wrapper', _this[0]).removeClass('on');
                                    }
                                });

                            timeline.from(firstSlide.find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.from(firstSlide.find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.from(firstSlide.find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.from(firstSlide.find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');

                            timeline.to($(this).find('.overlay-panel-content'), 3, { y: -200, opacity: 0, ease: Power2.easeInOut }, "+=2")
                                .call(() => {
                                    if (!$(this).hasClass('active')) {
                                        $(this).addClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).addClass('active');
                                    } else {
                                        $(this).removeClass('active');
                                        var indicator = $(this).attr('data-indicator');
                                        $('#' + indicator).removeClass('active');
                                    }
                                });

                            timeline.to($(this).find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                            timeline.to($(this).find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        }
                    } else if (i == slides.length - 1) {

                        timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut }, '-=1');

                        timeline.from($(this).find('.overlay-panel-content'), 2, { y: 200, opacity: 0, ease: Power2.easeInOut }, '-=1')
                            .call(() => {
                                if (!$(this).hasClass('active')) {
                                    $(this).addClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).addClass('active');
                                } else {
                                    $(this).removeClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).removeClass('active');
                                }
                            });

                        timeline.from($(this).find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');

                        timeline.to($(this).find('.overlay-panel-content'), 2, { y: 0, opacity: 1, ease: Power2.easeInOut });

                    } else {

                        timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut }, '-=1');

                        timeline.from($(this).find('.overlay-panel-content'), 2, { y: 200, opacity: 0, ease: Power2.easeInOut }, "-=1")
                            .call(() => {
                                if (!$(this).hasClass('active')) {
                                    $(this).addClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).addClass('active');
                                } else {
                                    $(this).removeClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).removeClass('active');
                                }
                            });;

                        timeline.from($(this).find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.from($(this).find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');

                        timeline.to($(this).find('.overlay-panel-content'), 2, { y: -200, opacity: 0, ease: Power2.easeInOut }, '+=2');

                        timeline.to($(this).find('.corner.top-left'), 1, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.to($(this).find('.corner.top-right'), 1, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.to($(this).find('.corner.bottom-right'), 1, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, '-=1');
                        timeline.to($(this).find('.corner.bottom-left'), 1, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, '-=1')
                            .call(() => {
                                if (!$(this).hasClass('active')) {
                                    $(this).addClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).addClass('active');
                                } else {
                                    $(this).removeClass('active');
                                    var indicator = $(this).attr('data-indicator');
                                    $('#' + indicator).removeClass('active');
                                }
                            });;

                    }

                });

                var duration = (slides.length * 200);
                var options = {
                    triggerElement: _this[0],
                    triggerHook: 'onLeave',
                    // duration:duration + '%',
                    duration: duration + '%',

                    // reverse:true,  
                }

                var sceneOffsetOverlay = new ScrollMagic.Scene(options)
                    .setPin(_this[0])
                    .setTween(timeline)
                    // .addIndicators({name: 'Offset Overlay Pin', indent: 420, colorStart: 'yellow',colorTrigger: 'yellow',})
                    .addTo(offsetOverlayController);

                // if(_this.offset().top !== 0) {
                // 	sceneOffsetOverlay.on('start', function() {
                // 		if(!$('.offset-indicator-wrapper', _this[0]).hasClass('on')) {
                // 			$('.offset-indicator-wrapper', _this[0]).addClass('on');
                // 		} else {
                // 			$('.offset-indicator-wrapper', _this[0]).removeClass('on');
                // 		}
                // 	})
                // } else {
                // 	$('.offset-indicator-wrapper', _this[0]).addClass('on');
                // }

            } else {

                TweenMax.to(firstSlide, 0, { autoAlpha: 1, ease: Power2.easeInOut });
                timeline.from(firstSlide.find('.overlay-panel-content'), 0.75, { y: 200, opacity: 0, ease: Power2.easeInOut }, 0);
                timeline.from(firstSlide.find('.corner.top-left'), 0.5, { y: -100, x: -100, opacity: 0, ease: Power2.easeInOut }, 0);
                timeline.from(firstSlide.find('.corner.top-right'), 0.5, { y: -100, x: 100, opacity: 0, ease: Power2.easeInOut }, 0);
                timeline.from(firstSlide.find('.corner.bottom-right'), 0.5, { y: 100, x: 100, opacity: 0, ease: Power2.easeInOut }, 0);
                timeline.from(firstSlide.find('.corner.bottom-left'), 0.5, { y: 100, x: -100, opacity: 0, ease: Power2.easeInOut }, 0);

                new ScrollMagic.Scene({
                        triggerElement: _this[0],
                        triggerHook: 0.7,
                    })
                    .setTween(timeline)
                    // .addIndicators({name: 'Single Panel Tween', indent: 220, colorStart: 'white',colorTrigger: 'white',})
                    .addTo(offsetOverlayController);

            }

            if ($(window).outerWidth() > 991) {
                var triggerHook = 0.7;
                var speed = 1;
            } else {
                var triggerHook = 0.5;
                var speed = 1.5
            }

            var overlayTween = TweenMax.from(overlay, speed, { x: '-150%', opacity: 1, ease: Power2.easeOut })
            var options2 = {
                triggerElement: _this[0],
                triggerHook: triggerHook,
                // duration:'100%'
            }

            new ScrollMagic.Scene(options2)
                .setTween(overlayTween)
                // .addIndicators({name: 'Offset Overlay', indent: 320, colorStart: 'pink',colorTrigger: 'pink',})
                .addTo(offsetOverlayController);

        });
    }
});