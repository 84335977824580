/*******************
 * Script Imports
 *******************/
import $ from "jquery";
window.$ = window.jQuery = $;

import "popper.js";
import "bootstrap";
import "slick-slider";
import "./../node_modules/bootstrap-table/dist/bootstrap-table.js";
import "./blocks/image-slider/image-slider";
import "./blocks/tabs/tabs";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min.js';

import 'select2/dist/css/select2.min.css'; // This caused my my js files to work 
import 'select2'; // // This caused my my js files to work 


import "./vendor/vendor";
import "./utility/detect-ie";
import "./utility/is-touch-device";
import "./utility/acf-google-map";
import "./theme/scripts";
import "./theme/on-load.js";
import "./theme/on-scroll.js";
import "./theme/on-resize.js";

import "./blocks/basic-scroll-magic/basic-scroll-magic";
import "./blocks/header-overlay-scroll-magic/header-overlay-scroll-magic";
import "./blocks/offset-overlay-scroll-magic/offset-overlay-scroll-magic";
import "./blocks/horizontal-scroll-block/horizontal-scroll";
import "./blocks/tabs/select2.min.js";
import "./blocks/animation-with-content/animation-with-content";

/*******************
 * Style Imports
 *******************/
import "../scss/main.scss";