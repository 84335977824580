jQuery(function() {

    if($('.basic-content-scroll-magic').length > 0) {

        var basicController = new ScrollMagic.Controller();
    
        var basicSection = $('.basic-content-scroll-magic');

        basicSection.each(function() {

            var _this = $(this);

            var slides = $('div.basic-panel', _this);
        
            var firstSlide = $('div.basic-panel-1', _this);
        
            var backgroundImage = $('.basic-background-pattern', _this);
            // var overlay = $('.overlay.pink', _this);
        
            var timeline = new TimelineMax();

			if(slides.length > 1) {

				slides.each(function(i) {
			
					if(i == 0) {
						if(_this.offset().top == 0) {
							TweenMax.to(firstSlide, 0, {autoAlpha : 1, ease: Power2.easeInOut});
							TweenMax.from(firstSlide.find('.basic-panel-content'), 0.75, { y:200, opacity:0, ease:Power2.easeInOut});
							TweenMax.from(firstSlide.find('.corner.top-left'), 0.5, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut});
							TweenMax.from(firstSlide.find('.corner.top-right'), 0.5, { y:-100, x:100, opacity:0, ease:Power2.easeInOut});
							TweenMax.from(firstSlide.find('.corner.bottom-right'), 0.5, { y:100, x:100, opacity:0, ease:Power2.easeInOut});
							TweenMax.from(firstSlide.find('.corner.bottom-left'), 0.5, { y:100, x:-100, opacity:0, ease:Power2.easeInOut});
				
							timeline.to($(this).find('.basic-panel-content'), 2, {y:-200, opacity:0, ease:Power2.easeInOut}, "+=1")
							.call(()=>{
								if(!$(this).hasClass('active')) {
									$(this).addClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).addClass('active');
									$('.basic-indicator-wrapper', _this[0]).addClass('on');
								} else {
									$(this).removeClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).removeClass('active');
									$('.basic-indicator-wrapper', _this[0]).removeClass('on');
								}
							});
							timeline.to($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
							timeline.to($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
							timeline.to($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
							timeline.to($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut},'-=1');
						} else {
							TweenMax.to(firstSlide, 0, {autoAlpha : 1, ease: Power2.easeInOut});
							timeline.from(firstSlide.find('.basic-panel-content'), 2, { y:200, opacity:0, ease:Power2.easeInOut}, '-=1')
							.call(()=>{
								if(!$(this).hasClass('active')) {
									$(this).addClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).addClass('active');
									$('.basic-indicator-wrapper', _this[0]).addClass('on');
								} else {
									$(this).removeClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).removeClass('active');
									$('.basic-indicator-wrapper', _this[0]).removeClass('on');
								}
							});
							timeline.from(firstSlide.find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
							timeline.from(firstSlide.find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
							timeline.from(firstSlide.find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
							timeline.from(firstSlide.find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
				
							timeline.to($(this).find('.basic-panel-content'), 3, {y:-200, opacity:0, ease:Power2.easeInOut, }, "+=2")
							.call(()=>{
								if(!$(this).hasClass('active')) {
									$(this).addClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).addClass('active');
								} else {
									$(this).removeClass('active');
									var indicator = $(this).attr('data-indicator');
									$('#' + indicator).removeClass('active');
								}
							});
							timeline.to($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
							timeline.to($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
							timeline.to($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
							timeline.to($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut},'-=1');
						}
					} else if(i == slides.length - 1) {
			
						timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut}, '-=1');
			
						timeline.from($(this).find('.basic-panel-content'), 2, { y:200, opacity:0, ease:Power2.easeInOut}, '-=1')
						.call(()=>{
							if(!$(this).hasClass('active')) {
								$(this).addClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).addClass('active');
							} else {
								$(this).removeClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).removeClass('active');
							}
						});
			
						timeline.from($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						
						timeline.to($(this).find('.basic-panel-content'), 2, {y:0, opacity:1, ease:Power2.easeInOut})
	
					} else {
						
						timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut}, '-=1');
			
						timeline.from($(this).find('.basic-panel-content'), 2, { y:200, opacity:0, ease:Power2.easeInOut}, "-=1")
						.call(()=>{
							if(!$(this).hasClass('active')) {
								$(this).addClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).addClass('active');
							} else {
								$(this).removeClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).removeClass('active');
							}
						});
			
						timeline.from($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.from($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						
						timeline.to($(this).find('.basic-panel-content'), 2, {y:-200, opacity:0, ease:Power2.easeInOut, }, '+=2')
						.call(()=>{
							if(!$(this).hasClass('active')) {
								$(this).addClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).addClass('active');
							} else {
								$(this).removeClass('active');
								var indicator = $(this).attr('data-indicator');
								$('#' + indicator).removeClass('active');
							}
						});
			
						timeline.to($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.to($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.to($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
						timeline.to($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
						
					}
			
				});
	
				var duration = (slides.length * 200);
				var options = {
					triggerElement: _this[0],
					triggerHook:'onLeave',
					// duration:duration + '%',
					duration:duration + '%',

					// reverse:true,  
				}
			
				var basicScene = new ScrollMagic.Scene(options)
				.setPin(_this[0])
				// .setClassToggle($('.basic-indicator-wrapper', _this[0])[0], 'on')
				.setTween(timeline)
				// .addIndicators({name: 'Offset Overlay Pin', indent: 420, colorStart: 'yellow',colorTrigger: 'yellow',})
				.addTo(basicController);

				// if(_this.offset().top !== 0) {
				// 	basicScene.on('start', function() {
				// 		if(!$('.basic-indicator-wrapper', _this[0]).hasClass('on')) {
				// 			$('.basic-indicator-wrapper', _this[0]).addClass('on');
				// 		} else {
				// 			$('.basic-indicator-wrapper', _this[0]).removeClass('on');
				// 		}
				// 	})
				// } else {
				// 	$('.basic-indicator-wrapper', _this[0]).addClass('on');
				// }

				// basicScene.on("leave", function (event) {
				// 	$('.basic-indicator-wrapper', _this[0]).removeClass('on');

				// });

	
				new ScrollMagic.Scene(options)
				.setTween(backgroundImage, {y: "-40%", ease: Linear.easeNone})
				// .addIndicators({name: 'Offset Overlay', indent: 320, colorStart: 'pink',colorTrigger: 'pink',})
				.addTo(basicController);

			} else {

				TweenMax.to(firstSlide, 0, {autoAlpha : 1, ease: Power2.easeInOut});
				timeline.from(firstSlide.find('.basic-panel-content'), 0.75, { y:200, opacity:0, ease:Power2.easeInOut}, 0);
				timeline.from(firstSlide.find('.corner.top-left'), 0.5, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut},0);
				timeline.from(firstSlide.find('.corner.top-right'), 0.5, { y:-100, x:100, opacity:0, ease:Power2.easeInOut},0);
				timeline.from(firstSlide.find('.corner.bottom-right'), 0.5, { y:100, x:100, opacity:0, ease:Power2.easeInOut},0);
				timeline.from(firstSlide.find('.corner.bottom-left'), 0.5, { y:100, x:-100, opacity:0, ease:Power2.easeInOut},0);				

				new ScrollMagic.Scene({
					triggerElement: _this[0],
					triggerHook:0.3,
				})
				.setTween(timeline)
				// .addIndicators({name: 'Single Panel Tween', indent: 220, colorStart: 'white',colorTrigger: 'white',})
				.addTo(basicController);

			}
        });
    } 
});