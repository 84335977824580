var didScroll;
var lastScrollTop = 0;
var delta = 10;
var navbarHeight = jQuery(".mj-navigation-wrapper").outerHeight(); // 86 to 56

var scrollUp = null;

jQuery(window).on('resize', function() {
    navbarHeight = jQuery(".mj-navigation-wrapper").outerHeight();
});

jQuery(window).on('scroll', function(e) {
    // Add 'scrolled' class based on window scroll position
    if (jQuery(window).scrollTop() > jQuery(window).height()) {
        jQuery('.mj-navigation').addClass('scrolled');
    } else {
        jQuery('.mj-navigation').removeClass('scrolled');
    }

    // Handle navbar visibility logic
    didScroll = true;

    setInterval(function(e) {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = jQuery(this).scrollTop();
        if (Math.abs(lastScrollTop - st) <= delta) return;

        if (st > 50) {
            jQuery('.mj-navigation-wrapper').addClass('active');
        } else {
            if (!jQuery('.search-takeover-wrapper').hasClass('active') && !jQuery('body').hasClass('search')) {
                jQuery('.mj-navigation-wrapper').removeClass('active');
            }
        }

        if (!jQuery('.sub-menu-box').hasClass('open') && !jQuery('.search-takeover-wrapper').hasClass('active')) {
            if (st > lastScrollTop && st > navbarHeight * 4) {
                if (jQuery('body').hasClass('admin-bar')) {
                    jQuery(".mj-navigation-wrapper").css({
                        'top': -(navbarHeight) + 32,
                    });
                } else {
                    jQuery(".mj-navigation-wrapper").css({
                        'top': -(navbarHeight),
                    });
                }

                scrollUp = st;

            } else if (st > lastScrollTop) {
                return;
            } else {
                if (scrollUp - (navbarHeight * 3) > st) {
                    if (jQuery('body').hasClass('admin-bar')) {
                        jQuery(".mj-navigation-wrapper").css({
                            'top': '32px',
                        });
                    } else {
                        jQuery(".mj-navigation-wrapper").css({
                            'top': 0,
                        });
                    }
                }
            }
        }

        lastScrollTop = st;
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const lenis = new Lenis({
        lerp: 0.1,
        wheelMultiplier: 0.7,
        gestureOrientation: 'vertical',
        normalizeWheel: true,
        smoothTouch: false
    });

    const doubleSections = document.querySelector('.double-sections');

    function toggleScrollAtZeroVH() {
        const rect = doubleSections.getBoundingClientRect();

        if (rect.top === 0) {
            doubleSections.style.overflowY = 'scroll';
        } else {
            doubleSections.style.overflowY = 'hidden';
        }
    }

    function disableLenisOnScroll() {
        doubleSections.addEventListener('mouseenter', function() {
            lenis.stop();
        });

        doubleSections.addEventListener('mouseleave', function() {
            lenis.start();
        });

        doubleSections.addEventListener('wheel', function(e) {
            e.stopPropagation();
        });

        doubleSections.addEventListener('touchmove', function(e) {
            e.stopPropagation();
        });
    }

    if (doubleSections) {
        disableLenisOnScroll();
        window.addEventListener('scroll', toggleScrollAtZeroVH);
    }

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
});