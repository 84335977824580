$(function() {
    if ($("body").hasClass("home")) {
        $(window).on("beforeunload", function() {
            $(window).scrollTop(0);
        });
    }

    $("body").addClass("FOUC");

    var words = ["inspireyou", "inspiredcontent"];
    $.each(words, function(i, value) {
        var template = '$1<span class="inspire-you">$2</span>$3';
        var pattern = new RegExp("(>[^<.]*)(" + value + ")([^<.]*)", "ig");
        var content = $("body").html();
        $("body").html(content.replace(pattern, template));
    });

    // MEMU FUNCTIONALITY

    if ($("body").hasClass("admin-bar")) {
        $(".mj-navigation-wrapper").addClass("admin");
    }

    var subMenuBox = $(".sub-menu-box");
    // console.log($('.mj-navigation-wrapper').outerHeight());
    // subMenuBox.css({'top':$('.mj-navigation-wrapper').outerHeight()});
    var subMenuBoxWidth = subMenuBox.outerWidth();
    var subMenuBoxHeight = subMenuBox.outerHeight();
    var subMenuColumns = 1;
    var subMenuList = $(".mj-menu > .menu > li:has(ul) > .sub-menu-wrapper");

    subMenuList.css({
        height: subMenuBoxHeight + 25,
        width: subMenuBoxWidth,
    });

    if ($("body").hasClass("search")) {
        $(".mj-navigation-wrapper").addClass("active");
        $(".mj-navigation-wrapper").addClass("active-border-bottom");
    } else if ($(window).scrollTop() > 20) {
        $(".mj-navigation-wrapper").addClass("active");
    }

    $(".mj-menu > .menu > li:has(.sub-menu-wrapper)")
        .on("mouseenter", function(e) {
            $(".sub-menu-box").addClass("open");
            $(".sub-menu-search").appendTo($(this).children(".sub-menu-wrapper"));
            $(".sub-menu-links").appendTo($(this).children(".sub-menu-wrapper"));

            $(this).children(".sub-menu-wrapper").css("display", "none");

            $(this)
                .children(".sub-menu-wrapper")
                .css("right", "0")
                .fadeIn(0, "linear");
            $(this)
                .children(".sub-menu-wrapper")
                .find("ul.sub-menu")
                .css("opacity", "1");
        })
        .on("mouseleave", function(e) {
            $(".sub-menu-box").removeClass("open");
            $(".sub-menu-search").appendTo($(".sub-menu-box"));
            $(".sub-menu-links").appendTo($(".sub-menu-box"));

            $(this).children(".sub-menu-wrapper").css("right", "-9999em");
            $(this)
                .children(".sub-menu-wrapper")
                .find("ul.sub-menu")
                .css("opacity", "0");
        });

    $(".mj-menu")
        .on("mouseenter", function() {
            $(".anchor-link-navigation").fadeOut(100);
        })
        .on("mouseleave", function() {
            if (!$(".search-takeover-wrapper").hasClass("active"))
                $(".anchor-link-navigation").fadeIn(250);
        });

    $(".mj-navigation .mj-search").on("click", function() {
        if ($(window).scrollTop() <= 20) {
            $(".mj-navigation-wrapper").addClass("active");
        }

        if ($(".search-takeover-wrapper").hasClass("active")) {
            $(".search-takeover-wrapper").removeClass("active");
            $(".mj-mobile-search").removeClass("open");
            $(".anchor-link-navigation").fadeIn(150);
            if ($(window).scrollTop() <= 20 && !$("body").hasClass("search")) {
                $(".mj-navigation-wrapper").removeClass("active-border-bottom");
            }
            $("body").removeClass("overflow");
        } else {
            $(".search-takeover-wrapper").addClass("active");
            $(".mj-mobile-search").addClass("open");
            $(".anchor-link-navigation").fadeOut(150);
            $(".mj-navigation-wrapper").addClass("active-border-bottom");
            $("body").addClass("overflow");
        }
    });
    $(".search-takeover-wrapper .close").on("click", function() {
        if ($(window).scrollTop() <= 20 && !$("body").hasClass("search")) {
            $(".mj-navigation-wrapper").removeClass("active");
        }
        $(".anchor-link-navigation").fadeIn(150);
        $(".mj-mobile-search").removeClass("open");

        $(".search-takeover-wrapper").removeClass("active");
        if (!$("body").hasClass("search")) {
            $(".mj-navigation-wrapper").removeClass("active-border-bottom");
        }
        $("body").removeClass("overflow");
    });

    //MENU ANCHOR LINK NAVIGATION FUNCTIONALITY

    if ($(".anchor-link-navigation").length > 0) {
        //POSITIONING THE BLOCK TO NAV
        let anchorLinkNav = $(".anchor-link-navigation");
        // anchorLinkNav.css({
        //     'top':$('.mj-navigation-wrapper').outerHeight()
        // });

        //SMOOTH SCROLLING FOR LINKS
        $("a[href*=\\#]", anchorLinkNav).on("click", function(event) {
            event.preventDefault();
            $("html,body").animate({ scrollTop: $(this.hash).offset().top - 200 },
                500
            );
        });

        //SCENE FOR NAVIGATION
        var anchorLinkController = new ScrollMagic.Controller();

        $(".anchor-link-navigation .anchor-link").each(function(i) {
            var _this = $(this);
            var hrefString = $(this).find("a").attr("href");
            var anchorArray = [];

            anchorArray[i] = new ScrollMagic.Scene({
                    triggerElement: $(hrefString)[0],
                    triggerHook: 0.25,
                })
                // .addIndicators()
                .addTo(anchorLinkController);

            anchorArray[i].on("enter", function() {
                _this.addClass("active");
                // setTimeout(function() {
                //   _this.siblings().removeClass('active');
                // }, 400);
                _this.siblings().removeClass("active");
            });
            anchorArray[i].on("leave", function() {
                // setTimeout(function() {
                //   _this.removeClass('active');
                // }, 400);
                _this.removeClass("active");
                _this.prev().addClass("active");
            });
        });
    }

    // MOBILE MENU FUNCTIONALITY

    $(".mobile-hamburger-wrapper").click(function() {
        if ($(".mj-mobile-search").hasClass("open")) {
            $(".mj-mobile-search").removeClass("open");

            if (!$(".search-takeover-wrapper").hasClass("active")) {
                $(".search-takeover-wrapper").addClass("active");
                $(".anchor-link-navigation").fadeOut(150);
                $(".mj-navigation-wrapper").addClass("active-border-bottom");
                $("body").addClass("overflow");
            } else {
                $(".search-takeover-wrapper").removeClass("active");
                $(".anchor-link-navigation").fadeIn(150);
                $(".mj-navigation-wrapper").removeClass("active-border-bottom");
                $("body").removeClass("overflow");
            }
        }
        $(this).toggleClass("open");
        if (!$(".mj-mobile-menu-takeover").hasClass("open")) {
            $(".mj-mobile-menu-takeover").addClass("open");
        } else {
            $(".mj-mobile-menu-takeover").removeClass("open");
        }
    });
    $(".mj-mobile-search").on("click", function() {
        if ($(".mobile-hamburger-wrapper").hasClass("open")) {
            $(".mobile-hamburger-wrapper").removeClass("open");
            if (!$(".mj-mobile-menu-takeover").hasClass("open")) {
                $(".mj-mobile-menu-takeover").addClass("open");
            } else {
                $(".mj-mobile-menu-takeover").removeClass("open");
            }
        }
        if (!$(".search-takeover-wrapper").hasClass("active")) {
            $(".search-takeover-wrapper").addClass("active");
            $(".anchor-link-navigation").fadeOut(150);
            $(".mj-navigation-wrapper").addClass("active-border-bottom");
            $("body").addClass("overflow");
        } else {
            $(".search-takeover-wrapper").removeClass("active");
            $(".anchor-link-navigation").fadeIn(150);
            $(".mj-navigation-wrapper").removeClass("active-border-bottom");
            $("body").removeClass("overflow");
        }
        $(this).toggleClass("open");
    });

    $(".mj-mobile-menu-container > ul > li.menu-item-has-children").each(
        function() {
            var link = $(this).find("> a");
            $(this)
                .find(".mobile-sub-menu-wrapper > ul.child-sub-menu")
                .prepend("<li>" + link[0].outerHTML + "</li>");
        }
    );
    $(
        ".mj-mobile-menu-container  ul.child-sub-menu > li.menu-item-has-children"
    ).each(function() {
        var link = $(this).find("> a");
        $(this)
            .find(".mobile-sub-menu-wrapper > ul.grandchild-sub-menu")
            .prepend("<li>" + link[0].outerHTML + "</li>");
    });

    $(".mj-mobile-menu-container > ul > li.menu-item-has-children > a").on(
        "click",
        function(e) {
            var link = $(this);

            if (link.hasClass("active")) {
                window.location.href = link.attr("href");
            } else {
                e.preventDefault();
                $(
                    ".mj-mobile-menu-container > ul > li.menu-item-has-children:not(this) > a"
                ).removeClass("active");
                link.addClass("active");
                $(
                    ".mj-mobile-menu-container > ul > li.menu-item-has-children:not(this) > .mobile-sub-menu-wrapper"
                ).removeClass("open");
                $(".mobile-hamburger-wrapper").addClass("adjust-height");
                $(this).next().addClass("open");
            }
        }
    );

    $(
        ".mj-mobile-menu-container  ul.child-sub-menu > li.menu-item-has-children > a"
    ).on("click", function(e) {
        var link = $(this);

        if (link.hasClass("active")) {
            window.location.href = link.attr("href");
        } else {
            e.preventDefault();
            $(this).parents(":eq(2)").addClass("sub-menu-open");
            $(this).parents(":eq(2)").addClass("hide-back");
            $(
                ".mj-mobile-menu-container ul.child-sub-menu > li.menu-item-has-children:not(this) > a"
            ).removeClass("active");
            link.addClass("active");
            $(
                ".mj-mobile-menu-container ul.child-sub-menu > li.menu-item-has-children:not(this) > .mobile-sub-menu-wrapper"
            ).removeClass("open");
            $(this).next().addClass("open");
        }
    });

    $(".mobile-menu-back").on("click", function(e) {
        e.stopPropagation();
        $(this).parents(".sub-menu-open").removeClass("hide-back");
        $(this).parents(".sub-menu-open").removeClass("sub-menu-open");
        $(this).parent().removeClass("open");
        $(this).parent().removeClass("sub-menu-open");
        $(this).parent().prev().removeClass("active");
        if ($(this).next().hasClass("child-sub-menu")) {
            $(".mobile-hamburger-wrapper").removeClass("adjust-height");
        }
    });

    //HEADER CLASSES
    if ($(".header-with-form").length > 0) {
        if ($(".header-with-form").offset().top < 33) {
            $(".header-with-form").addClass("is-top");
        }
    }

    if ($(".header-image-title-overlay").length > 0) {
        if ($(".header-image-title-overlay").offset().top >= 33) {
            $(".header-image-title-overlay").addClass("heading-align-center");
        }
    }
    if ($(".header-with-title").length > 0) {
        if ($(".header-with-title").offset().top >= 33) {
            $(".header-with-title").addClass("heading-align-center");
        }
    }

    // MASONRY POSTS
    function rearrangePostCards(parent, element) {
        // console.log(parent.outerWidth());
        let columns = 0;
        let colOne = [];
        let colTwo = [];
        let colThree = [];

        if (parent.outerWidth() > 1049) {
            element.each(function() {
                switch (columns) {
                    case 0:
                        colOne.push($(this)[0]);
                        if ($(window).outerWidth() < 768) {
                            columns = 0;
                        } else {
                            columns++;
                        }
                        break;
                    case 1:
                        colTwo.push($(this)[0]);
                        if ($(window).outerWidth() < 1200) {
                            columns = 0;
                        } else {
                            columns++;
                        }
                        break;
                    case 2:
                        colThree.push($(this)[0]);
                        columns = 0;
                        break;
                }
            });
            return [].concat(colOne, colTwo, colThree);
        } else {
            element.each(function() {
                switch (columns) {
                    case 0:
                        colOne.push($(this)[0]);
                        if ($(window).outerWidth() < 768) {
                            columns = 0;
                        } else {
                            columns++;
                        }
                        break;
                    case 1:
                        colTwo.push($(this)[0]);
                        columns = 0;
                        break;
                }
            });
            return [].concat(colOne, colTwo);
        }
    }
    let newPostCardArray = rearrangePostCards(
        $(".post-card-wrapper"),
        $(".post-card-wrapper .post-card")
    );
    $(".post-card-wrapper").empty().append(newPostCardArray);
    $(".loader").css("display", "none");
    $(".post-card-wrapper").fadeIn("slow", function() {
        $(this).removeClass("loading");
    });

    // MASONRY GRID FUNCTIONALITY IF NEEDED FOR POST CARD WRAPPER

    // $(window).on('resize', function() {
    //   let newPostCardArray = rearrangePostCards($('.post-card-wrapper'), $('.post-card-wrapper .post-card'));
    //   $('.post-card-wrapper').empty().append(newPostCardArray);
    // })
    // console.log(rearrangePostCards($('.post-card-wrapper .post-card')));

    ////

    // function resizeGridItem(item){
    //   grid = document.getElementsByClassName("post-card-wrapper")[0];
    //   rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    //   rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    //   rowSpan = Math.ceil((item.querySelector('.post-card-content-wrapper').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    //     item.style.gridRowEnd = "span "+ (rowSpan);
    // }

    // function resizeAllGridItems(){
    //   allItems = document.getElementsByClassName("post-card");
    //   for(x=0;x<allItems.length;x++){
    //     resizeGridItem(allItems[x]);
    //   }
    // }

    // function resizeInstance(instance){
    //   item = instance.elements[0];
    //   resizeGridItem(item);
    // }

    // window.onload = resizeAllGridItems();
    // window.addEventListener("resize", resizeAllGridItems);

    ////

    // allItems = document.getElementsByClassName("post-card-wrapper");
    // for(x=0;x<allItems.length;x++){
    //   imagesLoaded( allItems[x], resizeInstance);
    // }

    //ASSOCIATE TEAM MEMBER FUNCTIONALITY

    if ($(window).outerWidth() > 991) {
        $(".associate-team-info").unbind();
        if ($(".associate-team-wrapper").length > 0) {
            $(window).on("click", function(e) {
                if (!$(e.target).closest(".associate-team-member").hasClass("open")) {
                    $(".associate-team-member").each(function() {
                        $(this).removeClass("open").removeClass("off");
                    });
                }
            });
        }
        $(".associate-team-info").on("click", function() {
            let _this = $(this).parent();
            if (!_this.hasClass("open")) {
                $(".associate-team-member").each(function() {
                    $(this).not(_this).addClass("off");
                    if ($(this).not(_this).hasClass("open")) {
                        $(this).removeClass("open");
                        _this.removeClass("off");
                    }
                });
                _this.addClass("open");
            } else {
                $(".associate-team-member").each(function() {
                    $(this).not(_this).removeClass("off");
                });
                _this.removeClass("open");
                $(".associate-team-overlay").removeClass("open");
            }
        });
        $(".associate-team-member .bio-close").on("click", function() {
            $(".associate-team-member").each(function() {
                if ($(this).hasClass("open")) {
                    $(this).removeClass("open");
                }
                $(this).removeClass("off");
            });
        });
    } else {
        $(".associate-team-info").on("click", function() {
            let modalID = $(this).parent().attr("data-member");
            $("#" + modalID).modal("show");
        });
    }

    $(window).on("resize", function() {
        if ($(window).outerWidth() > 991) {
            $(".associate-team-info").unbind();
            if ($(".associate-team-wrapper").length > 0) {
                $(window).on("click", function(e) {
                    if (!$(e.target).closest(".associate-team-member").hasClass("open")) {
                        $(".associate-team-member").each(function() {
                            $(this).removeClass("open").removeClass("off");
                        });
                    }
                });
            }
            $(".associate-team-info").on("click", function() {
                let _this = $(this).parent();
                if (!_this.hasClass("open")) {
                    $(".associate-team-member").each(function() {
                        $(this).not(_this).addClass("off");
                        if ($(this).not(_this).hasClass("open")) {
                            $(this).removeClass("open");
                            _this.removeClass("off");
                        }
                    });
                    _this.addClass("open");
                } else {
                    $(".associate-team-member").each(function() {
                        $(this).not(_this).removeClass("off");
                    });
                    _this.removeClass("open");
                    $(".associate-team-overlay").removeClass("open");
                }
            });
            $(".associate-team-member .bio-close").on("click", function() {
                $(".associate-team-member").each(function() {
                    if ($(this).hasClass("open")) {
                        $(this).removeClass("open");
                    }
                    $(this).removeClass("off");
                });
            });
        } else {
            $(".associate-team-info").unbind();
            $(".associate-team-info").on("click", function() {
                let modalID = $(this).parent().attr("data-member");
                $("#" + modalID).modal("show");
            });
        }
    });

    // SINGLE POST MORE INFORMATION

    $(".single-post-more-info").on("click", function() {
        $(this).addClass("open");
        $(".single-post-overlay-sidebar").addClass("open");
    });
    $(".single-post-mobile-sidebar .overlay-close").on("click", function() {
        $(".single-post-overlay-sidebar").removeClass("open");
        $(".single-post-more-info").removeClass("open");
    });

    //SELECT TWO FOR TEAM BLOCK

    $(".team-category-select").select2({
        theme: "bootstrap4",
        width: "100%",
        placeholder: "Team Filter:",
        allowHtml: true,
    });

    var teamSelected = $(".team-category-select").val();

    $(".team-category-select").on("select2:close", function(e) {
        var pathArray = [];
        var data = $(this).select2("data");
        // console.log(data);
        if (teamSelected !== data[0].id) {
            data.map((selection) => {
                pathArray.push(selection.id);
            });
            var pathString = pathArray.join(",");
            if (pathString !== "") {
                window.location.href = "/associate/" + pathString + "/";
            } else {
                // window.location.href = '/our-team/';
            }
        }
    });

    $(".single-category-select").select2({
        theme: "bootstrap4",
        width: "100%",
        placeholder: "All:",
        allowHtml: true,
    });

    var singleSelected = $(".single-category-select").val();

    $(".single-category-select").on("select2:close", function(e) {
        var pathArray = [];
        var data = $(this).select2("data");
        if (singleSelected !== data[0].id) {
            data.map((selection) => {
                pathArray.push(selection.id);
            });
            var pathString = pathArray.join(",");
            if (pathString !== "") {
                window.location.href = "/category/" + pathString + "/";
            } else {
                // window.location.href = '/our-team/';
            }
        }
    });

    // POST ARCHIVE FILTER FUNCTIONALITY

    $(".post-category-wrapper .filter-pill-button").each(function() {
        if ($(this).hasClass("active-filter")) {
            $(".post-filter-wrapper").addClass("active-filter-height");
        }
    });

    $(".post-filter").on("click", function() {
        if (!$(this).hasClass("open")) {
            $(this).addClass("open");
            $(".post-filter-wrapper").removeClass("closed");
            $(".post-filter-wrapper").addClass("open");
            $(".post-category-wrapper .filter-pill-button").each(function() {
                $(".post-filter-wrapper").removeClass("active-filter-height");
            });
        } else {
            $(this).removeClass("open");
            $(".post-filter-wrapper").addClass("closed");
            $(".post-filter-wrapper").removeClass("open");
            $(".post-category-wrapper .filter-pill-button").each(function() {
                if ($(this).hasClass("active-filter")) {
                    $(".post-filter-wrapper").addClass("active-filter-height");
                }
            });
        }
    });

    $(".post-apply-filter .filter-button").click(function() {
        var categorySlugArray = [];
        $(".single-category.active-filter").each(function() {
            var activeFilter = $(this).find("a");
            var filterSlug = activeFilter.data("category-slug");
            categorySlugArray.push(filterSlug);
        });

        var categorySlugString = categorySlugArray.toString();
        // var typeSlugString = typeSlugArray.toString();
        var url = "";

        if (categorySlugString !== "") {
            url = "/learning-center/mj-insight/?categories=" + categorySlugString;
        } else {
            url = "/learning-center/mj-insight/";
        }
        window.location.replace(url);
    });

    $(".post-clear").on("click", function() {
        $(".filter-pill-button").each(function() {
            if ($(this).hasClass("active-filter")) {
                $(this).removeClass("active-filter");
            }
        });
    });
    $(".filter-pill-button").on("click", function() {
        if ($(this).hasClass("active-filter")) {
            $(this).removeClass("active-filter");
            $(".filter-pill-button").each(function() {
                if (!$(":not(this)").hasClass("active-filter")) {
                    $(".post-filter-wrapper").removeClass("active-filter-height");
                }
            });
        } else {
            $(this).addClass("active-filter");
        }
    });

    // SEMINAR WEBINAR FILTER FUNCTIONALITY

    $(".seminar-webinar-type-category-wrapper .filter-type-category-button").each(
        function() {
            if ($(this).hasClass("active-filter")) {
                $(".seminar-webinar-filter-wrapper").addClass("active-filter-height");
            }
        }
    );

    $(".seminar-webinar-filter").on("click", function() {
        let seminarWebinarFilterWrapperHeight = $(
            ".seminar-webinar-filter-height"
        ).outerHeight();
        if (!$(this).hasClass("open")) {
            $(this).addClass("open");
            $(".seminar-webinar-filter-wrapper").addClass("open");
            $(".seminar-webinar-filter-wrapper").removeClass("closed");
            $(".seminar-webinar-filter-wrapper").removeClass("active-filter-height");

            // $('.seminar-webinar-filter-wrapper').outerHeight(seminarWebinarFilterWrapperHeight + 40);
            // $('.seminar-webinar-filter-wrapper').css({'min-height':seminarWebinarFilterWrapperHeight + 40});
        } else {
            $(this).removeClass("open");
            $(".seminar-webinar-filter-wrapper").removeClass("open");
            $(".seminar-webinar-filter-wrapper").addClass("closed");
            $(".filter-type-category-button").each(function() {
                if ($(this).hasClass("active-filter")) {
                    $(".seminar-webinar-filter-wrapper").addClass("active-filter-height");
                }
            });

            // $('.seminar-webinar-filter-wrapper').css({'min-height':80});
            // $('.seminar-webinar-filter-wrapper').outerHeight(80);
        }
    });

    $(".filter-type-category-button").on("click", function() {
        if ($(this).hasClass("active-filter")) {
            $(this).removeClass("active-filter");
            $(".filter-type-category-button").each(function() {
                if (!$(":not(this)").hasClass("active-filter")) {
                    $(".seminar-webinar-filter-wrapper").removeClass(
                        "active-filter-height"
                    );
                }
            });
        } else {
            $(this).addClass("active-filter");
        }
    });
    $(".seminar-webinar-clear").on("click", function() {
        $(".filter-type-category-button").each(function() {
            if ($(this).hasClass("active-filter")) {
                $(this).removeClass("active-filter");
            }
        });
    });
    $(".seminar-webinar-apply-filter .filter-button").on("click", function() {
        var categorySlugArray = [];
        var typeSlugArray = [];
        $(".single-category.active-filter").each(function() {
            var activeFilter = $(this).find("a");
            var filterSlug = activeFilter.data("category-slug");
            categorySlugArray.push(filterSlug);
        });

        $(".single-type.active-filter").each(function() {
            var activeFilter = $(this).find("a");
            var filterSlug = activeFilter.data("type-slug");
            typeSlugArray.push(filterSlug);
        });
        var categorySlugString = categorySlugArray.toString();
        var typeSlugString = typeSlugArray.toString();
        var url = "";
        if (categorySlugString !== "" && typeSlugString !== "") {
            url =
                "/learning-center/on-demand-webinars/?types=" +
                typeSlugString +
                "&categories=" +
                categorySlugString;
        } else if (categorySlugString !== "" && typeSlugString == "") {
            url =
                "/learning-center/on-demand-webinars/?categories=" + categorySlugString;
        } else if (categorySlugString == "" && typeSlugString !== "") {
            url = "/learning-center/on-demand-webinars/?types=" + typeSlugString;
        } else {
            url = "/learning-center/on-demand-webinars/";
        }
        window.location.replace(url);
    });

    //SEMINAR WEBINAR MODAL
    // $('.post-card-modal').on('show.bs.modal', function (e) {
    //   $('body').css('overflow', 'hidden');
    // }).on('hidden', function(){
    //     $('body').css('overflow', 'auto');
    // });

    // RESOURCE FILTER FUNCTIONALITY

    $(".resource-apply-filter .filter-button").on("click", function() {
        var categorySlugArray = [];
        $(".single-category.active-filter").each(function() {
            var activeFilter = $(this).find("a");
            var filterSlug = activeFilter.data("category-slug");
            categorySlugArray.push(filterSlug);
        });

        var categorySlugString = categorySlugArray.toString();
        // var typeSlugString = typeSlugArray.toString();
        var url = "";

        if (categorySlugString !== "") {
            url =
                "/learning-center/covid-19-resource-center/?categories=" +
                categorySlugString;
        } else {
            url = "/learning-center/covid-19-resource-center/";
        }

        window.location.replace(url);
    });

    // TESTIMONIAL SLIDER
    if ($(".testimonial-slider").length > 0) {
        $(".testimonial-slider").slick({
            dots: true,
            infinite: true,
            speed: 600,
            // autoplay:true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            accessibility: true,
        });
    }

    $(document).ready(function() {
        if ($(".academic-slider-wrapper").length > 0) {
            $(".academic-slider-wrapper").each(function() {
                var $thisWrapper = $(this);
                var isAuto =
                    $thisWrapper.find(".academic-slider").data("auto") === true;

                // Cache tabs and sliders
                const tabs = $thisWrapper.find(".academic-tab");
                const sliders = $thisWrapper.find(".academic-slider");

                // Function to initialize Slick for a specific slider
                function initializeSlick($slider) {
                    if (!$slider.hasClass("slick-initialized")) {
                        $slider.slick({
                            dots: false,
                            infinite: false,
                            speed: 1000,
                            autoplay: isAuto,
                            autoplaySpeed: 4000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            prevArrow: $thisWrapper.find(".prev-image-video"),
                            nextArrow: $thisWrapper.find(".next-image-video"),
                            adaptiveHeight: true,
                            variableWidth: true,
                            responsive: [{
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        arrows: true,
                                    },
                                },
                                {
                                    breakpoint: 992,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: true,
                                    },
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: true,
                                    },
                                },
                                {
                                    breakpoint: 576,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: true,
                                    },
                                },
                            ],
                        });
                        $slider.addClass("slick-initialized");
                    }
                }

                // Ensure the first tab is active on load and its slider is initialized and shown
                tabs.first().addClass("active");
                sliders.hide();
                sliders.first().addClass("active").show();
                initializeSlick(sliders.first());
                sliders.first().slick("setPosition");

                // Function to reset and reinitialize slider when tab is clicked
                function resetSlider($activeSlider) {
                    $activeSlider.slick("slickGoTo", 0);
                    $activeSlider.slick("setPosition");
                    $activeSlider
                        .find(".slick-prev, .slick-next")
                        .removeClass("slick-hidden")
                        .css("visibility", "visible");
                    $activeSlider
                        .find(".slick-prev, .slick-next")
                        .removeClass("slick-disabled");
                }

                // Tab click event to switch between sliders
                tabs.on("click", function() {
                    const index = $(this).data("tab-index");
                    tabs.removeClass("active");
                    sliders.removeClass("active").hide();
                    $(this).addClass("active");
                    const $activeSlider = sliders.filter(`[data-tab-index="${index}"]`);
                    $activeSlider.addClass("active").show();
                    initializeSlick($activeSlider);
                    resetSlider($activeSlider);
                });

                // Bind click event to image-wrapper to open modal
                $thisWrapper.find(".image-wrapper").on("click", function() {
                    const sliderHeader = $(this).data("slider-header");
                    const modalSubHeader = $(this).data("modal-subheader");
                    const modalTab = $(this).data("academic-tab");

                    // Set modal content
                    $("#modal-header").text(sliderHeader);
                    $("#modal-subheader").text(modalSubHeader);
                    $("#academic-tab").text(modalTab);

                    // Show modal
                    $("#modal").removeClass("hidden").addClass("visible");
                });

                // Close modal when the close button is clicked
                $(".close-modal").click(function() {
                    $("#modal").removeClass("visible").addClass("hidden");
                });

                // Optional: Close modal when clicking outside of modal content
                $(window).click(function(event) {
                    if ($(event.target).is("#modal")) {
                        $("#modal").removeClass("visible").addClass("hidden");
                    }
                });

                // Ensure Slick refreshes on window resize
                $(window).resize(function() {
                    sliders.slick("setPosition");
                });

                // Ensure Slick refreshes on window load
                $(window).on("load", function() {
                    sliders.slick("setPosition");
                });
            });
        }
    });

    $(document).ready(function() {
        if ($(".image-video-slider-wrapper").length > 0) {
            $(".image-video-slider-wrapper").each(function() {
                var isAuto = $(this).find(".image-video-slider").data("auto") === true;

                $(this)
                    .find(".image-video-slider")
                    .slick({
                        dots: false,
                        infinite: true, // Disable infinite scrolling
                        speed: 1000, // Adjust speed for smoother transition
                        autoplay: isAuto,
                        autoplaySpeed: 4000,
                        slidesToShow: 1, // Show only 1 slide at a time
                        slidesToScroll: 1, // Scroll only 1 slide at a time
                        centerMode: false, // Disable center mode
                        mobileFirst: true,
                        arrows: true,
                        variableWidth: false, // Enable variable width to scroll 70vw
                        prevArrow: $(this).find(".prev-image-video"),
                        nextArrow: $(this).find(".next-image-video"),
                        initialSlide: 0, // Start at the first slide
                        adaptiveHeight: true, // Adjust height based on slide content
                        responsive: [{
                                breakpoint: 991,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    variableWidth: true,
                                },
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    variableWidth: true,
                                },
                            },
                            {
                                breakpoint: 575,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    variableWidth: true,
                                },
                            },
                        ],
                    });

                // Ensure Slick refreshes on window resize
                $(window).resize(function() {
                    $(".image-video-slider").slick("refresh");
                });

                // Ensure Slick refreshes on window load
                $(window).on("load", function() {
                    $(".image-video-slider").slick("refresh");
                });
            });
        }
    });

    //     gsap.registerPlugin(ScrollTrigger);

    //     const typeSplit = new SplitType('[text-split]', {
    //         types: 'words',
    //         tagName: 'span'
    //     });

    //     document.querySelectorAll('[words-slide-up]').forEach((element) => {
    //         const words = element.querySelectorAll('.word');

    //         gsap.fromTo(words, { opacity: 0, y: 50 }, {
    //             opacity: 1,
    //             y: 0,
    //             duration: 1,
    //             ease: 'power2.out',
    //             stagger: 0.1,
    //             scrollTrigger: {
    //                 trigger: element,
    //                 start: 'top 20%', // Trigger when the element is 80% from the top of the viewport
    //                 end: 'bottom 20%',
    //                 toggleActions: 'play none none none' // Play animation once
    //             }
    //         });
    //     });
    // });

    // Smooth scrolling

    //CONTENT SLIDER
    if ($(".content-slider").length > 0) {
        $(".content-slider").each(function() {
            var parentId = $(this).closest(".content-slider-wrapper").attr("id");
            var isAuto = $(this).attr("data-auto");
            var isAutoFlag = isAuto ? true : false;
            var contentSliderArgs = {
                dots: false,
                infinite: true,
                speed: 600,
                autoplay: isAutoFlag,
                autoplaySpeed: 4000,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                // centerPadding:'20',
                arrows: true,
                prevArrow: $("#" + parentId + " .content-prev"),
                nextArrow: $("#" + parentId + " .content-next"),
                accessibility: true,
                variableWidth: true,
                adaptiveHeight: true,
            };
            $(this).slick(contentSliderArgs);
        });
        // $(window).resize(function(){
        //   $('.content-slider')[0].slick.refresh();
        // });
    }

    $(document).ready(function() {
        if ($(".post-slider").length > 0) {
            $(".post-slider").each(function() {
                var isAuto = $(this).attr("data-auto");
                var isAutoFlag = isAuto ? true : false;

                $(this)
                    .slick({
                        dots: false,
                        infinite: true,
                        speed: 600,
                        autoplay: isAutoFlag,
                        autoplaySpeed: 4000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        arrows: true,
                        prevArrow: $(".prev-post"),
                        nextArrow: $(".next-post"),
                        // Add event to update post-top on slide change
                        responsive: [{
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                            },
                        }, ],
                    })
                    .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
                        var $nextSlide = $(slick.$slides[nextSlide]);

                        // Update post-top content based on the upcoming slide's data
                        var newTitle = $nextSlide.attr("data-title");
                        var newDate = $nextSlide.attr("data-date");
                        var newExcerpt = $nextSlide.attr("data-excerpt");

                        $(".dynamic-title").fadeOut(300, function() {
                            $(this).text(newTitle).fadeIn(300);
                        });

                        $(".dynamic-date").fadeOut(300, function() {
                            $(this).text(newDate).fadeIn(300);
                        });

                        $(".dynamic-excerpt").fadeOut(300, function() {
                            $(this).text(newExcerpt).fadeIn(300);
                        });
                    });
            });
        }
    });

    //TIMELINE SLIDER
    if ($(".timeline").length > 0) {
        $(".timeline").each(function() {
            var parentId = $(this).closest(".timeline-wrapper").attr("id");
            // console.log(parentId);

            var slide_indicator = $(this).attr("data-slide-indicator");
            var set_indicator = slide_indicator ? true : false;

            $(this).on("init", function(slick) {
                // var slideIdicator = $(this).find('.slick-dots');
                var slideDots = $(this).find(".slick-dots > li");
                slideDots.each(function(i) {
                    $(this).attr("data-slide-count", i + 1);
                });
                var activeSlide = $(this).find(".slick-current");
                var activeSlideContent = activeSlide.find(".timeline-slide-wrapper");
                var activeSlideNumber = activeSlideContent.attr("data-slide-count");
                // activeSlideContent.addClass('active');
                $(".timeline-slide-count .timeline-active-number").html(
                    activeSlideNumber
                );
            });
            var timelineSliderArgs = {
                dots: set_indicator,
                infinite: true,
                speed: 600,
                swipeToSlide: true,
                // autoplay:true,
                // autoplaySpeed:4000,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                // centerPadding:'40%',
                accessibility: true,
                variableWidth: true,
                // adaptiveHeight: true,
                arrows: true,
                prevArrow: $("#" + parentId + " .timeline-prev"),
                nextArrow: $("#" + parentId + " .timeline-next"),
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        variableWidth: false,
                        slidesToShow: 1,
                        dots: false,
                    },
                }, ],
            };
            $(this).slick(timelineSliderArgs);

            // $(this).on('beforeChange', function() {
            //   $('.timeline-tick').addClass('move-left');
            //   setTimeout(function() {
            //   $('.timeline-tick').removeClass('move-left');
            //   },400);
            // });

            $(this).on("afterChange", function(slick, currentSlide) {
                var activeSlide = $(this).find(".slick-current");
                var activeSlideContent = activeSlide.find(".timeline-slide-wrapper");
                var activeSlideNumber = activeSlideContent.attr("data-slide-count");
                // activeSlideContent.addClass('active');
                $(".timeline-slide-count .timeline-active-number").html(
                    activeSlideNumber
                );

                var slideIndicator = $(this).find(".slick-dots");
                slideIndicator.animate({
                        scrollTop:
                            ($(
                                    '.slick-dots > li[data-slide-count="' +
                                    activeSlideNumber +
                                    '"]',
                                    this
                                ).height() *
                                activeSlideNumber) /
                            2,
                    },
                    "ease"
                );
            });
        });
    }

    // if ($(".bracket-content-image-slider").length > 0) {
    //     $(".bracket-content-image-slider").each(function() {
    //         let id = $(this).attr("data-id")
    //         $(this).slick({
    //                 lazyLoad: "ondemand",
    //                 infinite: true,
    //                 dots: true,
    //                 arrows: true,
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 centerMode: true,
    //                 mobileFirst: true,
    //                 accessibility: true,
    //                 // variableWidth: true,
    //                 adaptiveHeight: true,
    //                 prevArrow: $(this)
    //                     .siblings(".bracket-content-image-arrows")
    //                     .find(".prev-bracket-image-content-slider"),
    //                 nextArrow: $(this)
    //                     .siblings(".bracket-content-image-arrows")
    //                     .find(".next-bracket-image-content-slider"),
    //                 appendDots: $(this).siblings(".bracket-content-image-arrows"),
    //                 responsive: [{
    //                         breakpoint: 991,
    //                         settings: {
    //                             slidesToShow: 1,
    //                             slidesToScroll: 1,
    //                         },
    //                     },
    //                     {
    //                         breakpoint: 767,
    //                         settings: {
    //                             slidesToShow: 1,
    //                             slidesToScroll: 1,
    //                         },
    //                     },
    //                     {
    //                         breakpoint: 575,
    //                         settings: {
    //                             slidesToShow: 1,
    //                             slidesToScroll: 1,
    //                             arrows: false,
    //                         },
    //                     },
    //                 ],
    //             })
    //             //setting the arrow distance based off how long slick dots is
    //         let slickDotsWidth = $(this)
    //             .siblings(".bracket-content-image-arrows")
    //             .children(".slick-dots")
    //             .width()
    //         let tenWidth = slickDotsWidth / 2.5 + 42
    //         $(this)
    //             .siblings(".bracket-content-image-arrows")
    //             .children(".prev-bracket-image-content-slider")
    //             .css("right", tenWidth + "px")
    //         $(this)
    //             .siblings(".bracket-content-image-arrows")
    //             .children(".next-bracket-image-content-slider")
    //             .css("left", tenWidth + "px")
    //     })
    // }

    if ($(".illustration-content-slider").length > 0) {
        $(".illustration-content-slider").each(function() {
            let id = $(this).attr("data-id");
            // console.log(this)
            $(this).slick({
                lazyLoad: "ondemand",
                infinite: true,
                dots: true,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                mobileFirst: true,
                accessibility: true,
                // variableWidth: true,
                adaptiveHeight: true,
                prevArrow: $(this)
                    .siblings(".illustration-content-arrows")
                    .find(".prev-illustration-content-slider"),
                nextArrow: $(this)
                    .siblings(".illustration-content-arrows")
                    .find(".next-illustration-content-slider"),
                appendDots: $(this).siblings(".illustration-content-arrows"),
                responsive: [{
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        },
                    },
                ],
            });
            //setting the arrow distance based off how long slick dots is
            let slickDotsWidth = $(this)
                .siblings(".illustration-content-arrows")
                .children(".slick-dots")
                .width();
            let tenWidth = slickDotsWidth / 2.5 + 42;
            $(this)
                .siblings(".illustration-content-arrows")
                .children(".prev-illustration-content-slider")
                .css("right", tenWidth + "px");
            $(this)
                .siblings(".illustration-content-arrows")
                .children(".next-illustration-content-slider")
                .css("left", tenWidth + "px");
        });
    }

    //FORMIDABLE RADIO CHECKBOX

    $(".with_frm_style .frm_radio input[type=radio]").after("<span>"); // Remove this line if you do not wish to style radio buttons
    $(".with_frm_style .frm_checkbox input[type=checkbox]").after("<span>"); // Remove this line if you do not wish to style checkboxes

    // ADDING ARROWS TO PAGINATION LINKS
    $(".mj-pagination-wrapper a.next").parent().addClass("next-page");
    $(".mj-pagination-wrapper a.prev").parent().addClass("prev-page");
});

gsap.registerPlugin(ScrollTrigger);

// Function to lock and unlock the body scroll
function lockBodyScroll() {
    document.body.classList.add("scroll-lock");
}

function unlockBodyScroll() {
    document.body.classList.remove("scroll-lock");
}

window.onload = function() {
    // Ensure that SplitType, GSAP, and ScrollTrigger are loaded
    if (
        typeof SplitType === "undefined" ||
        typeof gsap === "undefined" ||
        typeof ScrollTrigger === "undefined"
    ) {
        console.error("SplitType, GSAP, or ScrollTrigger not loaded");
        return;
    }

    // Split text into spans using SplitType
    let typeSplit = new SplitType("[text-split]", {
        types: "words, chars",
        tagName: "span",
    });

    // Ensure the words are hidden initially (opacity: 0)
    gsap.set("[text-split] .word", { opacity: 0 });

    // Create scroll trigger animation for sliding up words
    $("[words-slide-up]").each(function() {
        let tl = gsap.timeline({
            paused: true,
        });

        // Animate from opacity: 0 to opacity: 1 and move the words upwards
        tl.fromTo(
            $(this).find(".word"), { opacity: 0, yPercent: 100 }, // Starting state: invisible and below
            {
                opacity: 1,
                yPercent: 0,
                duration: 0.5,
                ease: "power2.out",
                stagger: { amount: 0.5 },
                immediateRender: false,
            } // End state: visible and in place
        );

        // Create scroll trigger
        ScrollTrigger.create({
            trigger: $(this),
            start: "top 80%", // Start when the element reaches 80% of the viewport
            onEnter: () => tl.play(), // Play animation on enter
            onLeaveBack: () => tl.progress(0).pause(), // Reset animation when scrolling back
        });
    });

    // Refresh ScrollTrigger to ensure proper positioning
    ScrollTrigger.refresh();
};

// Ensure ScrollTrigger refreshes after everything is loaded
$(window).on("load", function() {
    ScrollTrigger.refresh();
});

document.addEventListener("DOMContentLoaded", function() {
    // Create the GSAP timeline
    const globeAnimation = gsap.timeline({ repeat: -1, yoyo: true });

    // Animate the ellipses to rotate infinitely
    globeAnimation.to("#ellipses", {
        rotation: 360,
        duration: 20,
        ease: "power1.inOut", // Smooth easing
        transformOrigin: "50% 50%",
    });
});