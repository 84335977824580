jQuery(function() {

    if($('.header-overlay-scroll-magic').length > 0) {

        var headerOverlayController = new ScrollMagic.Controller();
    
        var section = $('.header-overlay-scroll-magic');

        section.each(function() {

            var _this = $(this);

            var slides = $('div.header-panel', _this);
        
            var firstSlide = $('div.header-panel-1', _this);
        
            var backgroundImage = $('.header-overlay-background-pattern', _this);
        
            var timeline = new TimelineMax();

            slides.each(function(i) {

                function onStart() {
                    if(!firstSlide.closest('.header-panel').hasClass('active')) {
                        firstSlide.closest('.header-panel').addClass('active');
                        var indicator = firstSlide.closest('.header-panel').attr('data-indicator');
                        $('#'+ indicator).addClass('active');
                    } else {
                        firstSlide.closest('.header-panel').removeClass('active');
                        var indicator = firstSlide.closest('.header-panel').attr('data-indicator');
                        $('#' + indicator).removeClass('active');
                    }
                }
        
                if(i == 0) {
                    TweenMax.to(firstSlide, 0, {autoAlpha : 1, ease: Power2.easeInOut});
                    TweenMax.from(firstSlide.find('h1'), 0.75, { y:200, opacity:0, ease:Power2.easeInOut, onStart:onStart});
                    TweenMax.from(firstSlide.find('.corner.top-left'), 0.5, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut});
                    TweenMax.from(firstSlide.find('.corner.top-right'), 0.5, { y:-100, x:100, opacity:0, ease:Power2.easeInOut});
                    TweenMax.from(firstSlide.find('.corner.bottom-right'), 0.5, { y:100, x:100, opacity:0, ease:Power2.easeInOut});
                    TweenMax.from(firstSlide.find('.corner.bottom-left'), 0.5, { y:100, x:-100, opacity:0, ease:Power2.easeInOut});
        
                    timeline.to($(this).find('h1'), 2, {y:-200, opacity:0, ease:Power2.easeInOut}, "+=1")
                    .call(()=>{
                        if(!$(this).hasClass('active')) {
                            $(this).addClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).addClass('active');
                        } else {
                            $(this).removeClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).removeClass('active');
                        }
                    });
                    timeline.to($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.to($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
                    timeline.to($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut},'-=1');
                    timeline.to($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut},'-=1');

                } else if(i == slides.length - 1) {
        
                    timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut}, '-=1');
        
                    timeline.from($(this).find('h1'), 2, { y:200, opacity:0, ease:Power2.easeInOut}, '-=1')
                    .call(()=>{
                        if(!$(this).hasClass('active')) {
                            $(this).addClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).addClass('active');
                        } else {
                            $(this).removeClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).removeClass('active');
                        }
                    });;
        
                    timeline.from($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    
                    timeline.to($(this).find('h1'), 2, {y:0, opacity:1, ease:Power2.easeInOut} );

                } else {
                    
                    timeline.to($(this), 1, { autoAlpha: 1, ease: Power2.easeInOut}, '-=1');
        
                    timeline.from($(this).find('h1'), 2, { y:200, opacity:0, ease:Power2.easeInOut}, "-=1")
                    .call(()=>{
                        if(!$(this).hasClass('active')) {
                            $(this).addClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).addClass('active');
                        } else {
                            $(this).removeClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).removeClass('active');
                        }
                    });;
        
                    timeline.from($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.from($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    
                    timeline.to($(this).find('h1'), 2, {y:-200, opacity:0, ease:Power2.easeInOut}, '+=1')
                    .call(()=>{
                        if(!$(this).hasClass('active')) {
                            $(this).addClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).addClass('active');
                        } else {
                            $(this).removeClass('active');
                            var indicator = $(this).attr('data-indicator');
                            $('#' + indicator).removeClass('active');
                        }
                    });;
        
                    timeline.to($(this).find('.corner.top-left'), 1, { y:-100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.to($(this).find('.corner.top-right'), 1, { y:-100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.to($(this).find('.corner.bottom-right'), 1, { y:100, x:100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    timeline.to($(this).find('.corner.bottom-left'), 1, { y:100, x:-100, opacity:0, ease:Power2.easeInOut}, '-=1');
                    
                }
        
            });

            var duration = (slides.length * 200);
        
            var options = {
                triggerElement: _this[0],
                triggerHook:'onLeave',
                // duration:duration + '%',
                duration:duration + '%',
                reverse:true,  
            }
        
            var sceneheader = new ScrollMagic.Scene(options)
            .setPin(_this[0])
            .setTween(timeline)
            // .addIndicators({name: 'Header Trigger', indent: 520, colorStart: 'yellow',colorTrigger: 'yellow',})
            .addTo(headerOverlayController);
        
            new ScrollMagic.Scene(options)
            .setTween(backgroundImage, {y: "-40%", ease: Linear.easeNone})
            .addTo(headerOverlayController);
        });
    }
    
});